body splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  color: #184E77;
  z-index: 999999;
  pointer-events: none;
  opacity: 1;
  visibility: visible;
  transition: opacity 400ms cubic-bezier(0.4, 0, 0.2, 1);
}

body splash-screen img {
  width: 120px;
  max-width: 120px;
}

body splash-screen .spinner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 56px;
}

body splash-screen .spinner > div {
  width: 12px;
  height: 12px;
  background-color: #184E77;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1s infinite ease-in-out both;
  animation: bouncedelay 1s infinite ease-in-out both;
}

body splash-screen .spinner .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

body splash-screen .spinner .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@-webkit-keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

body:not(.splash-screen--hidden) {
  overflow: hidden;
}

body.splash-screen--hidden splash-screen {
  visibility: hidden;
  opacity: 0;
}
