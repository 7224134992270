pagination-controls {
  .ngx-pagination {
    margin-bottom: 0;

    li {
      @apply rounded-sm mx-1;
      a {
        @apply hover:bg-hover text-hint rounded-sm;
      }
    }

    .current {
      @apply bg-accent text-white;
    }
  }
}
