.dialog {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &__wrapper {
    max-width: 100% !important;
    width: 800px;
    max-height: 100%;
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  &__close {
    position: absolute;
    right: 0;
    top: 0;
    padding: 16px 16px 0 0;
  }

  &__back {
    position: absolute;
    left: 0;
    top: 0;
    padding: 16px 0 0 16px;
  }

  &__header {
    padding: 16px 24px;
  }

  &__body {
    max-height: calc(100vh - 150px);
    padding: 16px 24px;
    overflow-y: auto;
    overflow-x: hidden;
  }

  &__footer {
    padding: 16px 24px;
  }
}
