// Basic
.tag {
  @apply text-sm py-1 px-2 inline-flex font-normal select-none rounded bg-surface text-current whitespace-nowrap;

  // Rounded
  &--rounded {
     @apply rounded-full;
  }

  // Alternative
  &--primary {
     @apply bg-primary text-white;
  }

  &--accent {
     @apply bg-accent text-white;
  }

  &--success {
     @apply bg-success text-white;
  }

  &--info {
     @apply bg-info text-white;
  }

  &--warning {
     @apply bg-warn text-white;
  }

  &--danger {
     @apply bg-danger text-white;
  }
}


