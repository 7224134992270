@tailwind base;
@layer base {
  html,
  body {
    @apply w-full h-full;
  }

  html {
    @apply overflow-hidden;
  }

  body {
    @apply text-base font-sans text-default bg-default bg-opacity-70 overflow-auto scroll-smooth;
  }

  a {
    @apply text-accent;

    &:hover,
    &:focus {
      @apply underline;
    }
  }

  hr {
    border-width: 0;
    height: 2px;
  }

  ::selection {
    @apply bg-accent text-on-accent;
  }

  .print-page {
    width: 21cm;
    height: 29.7cm;
    margin: 30mm 45mm 30mm 45mm;
  }
}
@tailwind components;
@tailwind utilities;
